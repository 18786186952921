<template>

  <div id="erro">
    <!-- <img src="../assets/imagens/logo-npc-escura.png" alt="VIP Delivery" /> -->
    <div id="erro-desc">Loja não encontrada! Verifique!</div>
  </div>

</template>

<style>

  #erro {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }

  #erro-desc {
    font-size: 1.9rem;
    margin-top: 2rem;
    color: #213966;
  }

</style>